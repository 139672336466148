<script setup lang="ts">
import qs from "qs";

function isExternalUrl(url: string): boolean {
  try {
    const parsedUrl = new URL(url, window.location.href);
    return parsedUrl.hostname !== window.location.hostname;
  } catch (e) {
    // If the URL is invalid, consider it as not external
    return false;
  }
}

const query = qs.stringify(
  {
    populate: {
      slider: {
        populate: {
          backgroundImage: {
            fields: ["url", "name"],
          },
          image: {
            fields: ["url", "name"],
          },
          advantages: {
            populate: "*",
          },
        },
      },
    },
  },
  {
    encodeValuesOnly: true,
  },
);

const { data } = useCustomFetch(`/api/strapi-full/homepage?${query}`);
const sliderData = computed(() => data?.value?.data?.attributes?.slider);
</script>

<template>
  <div id="mainslider" class="carousel slide hero-slider">
    <div class="carousel-indicators">
      <button
        v-for="(slide, index) in sliderData"
        :key="slide.id"
        data-bs-target="#mainslider"
        :data-bs-slide-to="index"
        :aria-label="`Slide ${index + 1}`"
        :class="{ active: index === 0 }"
        :aria-current="index === 0"
      ></button>
    </div>

    <div class="carousel-inner">
      <div
        v-for="(slide, index) in sliderData"
        :key="slide.id"
        class="carousel-item"
        :style="
          slide.backgroundImage?.data
            ? {
                backgroundImage: `url(${$getStrapiImageSrc(slide?.backgroundImage)})`,
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
              }
            : undefined
        "
        :class="{
          active: index === 0,
          invert: slide.dark,
        }"
      >
        <div class="row">
          <div class="col-md-6" data-aos="fade-down" data-aos-delay="100">
            <div class="slide-content-wrapper slide-content-style">
              <h3 v-html="slide.title"></h3>
              <h3 v-html="slide.smallTitle"></h3>
              <p v-html="slide.subTitle"></p>

              <div v-if="slide.advantages" class="slide-features">
                <span v-for="advantage in slide.advantages" :key="advantage.id">
                  <img
                    :src="$getStrapiImageSrc(advantage.image)"
                    alt=""
                    height="20"
                  />
                  {{ advantage.name }}
                </span>
              </div>

              <div class="slide-buttons">
                <NuxtLinkLocale
                  :to="slide.startButtonLink || '/register'"
                  :external="isExternalUrl(slide.startButtonLink)"
                  class="buttons inline"
                  data-aos="zoom-out"
                  data-aos-delay="300"
                >
                  {{ slide.startButtonText }}
                </NuxtLinkLocale>
                <NuxtLinkLocale
                  :to="slide.demoButtonLink || '/register'"
                  :external="isExternalUrl(slide.demoButtonLink)"
                  class="buttons outline"
                  data-aos="zoom-out"
                  data-aos-delay="600"
                >
                  {{ slide.demoButtonText }}
                </NuxtLinkLocale>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="slide-content-wrapper slide-image">
              <img
                :src="$getStrapiImageSrc(slide.image)"
                data-aos="zoom-out"
                class="img-fluid d-block"
                style="margin-left: auto"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
