import type { UseFetchOptions } from "nuxt/app";

export function useCustomFetch<T>(
  url: string | (() => string),
  options: UseFetchOptions<T> = {},
) {
  const { setIsLoading } = useIsLoading();
  const { locale } = useI18n();
  const { $fetch } = useNuxtApp();

  options.headers = {
    ...options.headers,
    "Accept-Language": locale,
  };

  return useFetch<T>(url, {
    ...options,
    $fetch,
    onRequest() {
      setIsLoading(true);
    },
    onResponse() {
      setIsLoading(false);
    },
    onResponseError() {
      setIsLoading(false);
    },
  });
}
