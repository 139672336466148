<script lang="ts">
export default {
  mounted: () => {
    const { $bootstrap } = useNuxtApp();

    function getCookie(cookieName: string) {
      const cookies = document?.cookie?.split(";");

      for (let cookie of cookies) {
        cookie = cookie?.trim();
        if (cookie?.startsWith(cookieName + "=")) {
          return cookie?.substring(cookieName.length + 1);
        }
      }

      return null;
    }

    const accepted = getCookie("accepted");

    const redirectData = sessionStorage.getItem("redirectForLanguage");

    function checkEUUser() {
      fetch("https://ipapi.co/json/")
        .then((response) => response.json())
        .then((data) => {
          const countryCode = data.country;

          const spanishSpeakingCountryCodes = [
            "AR",
            "BO",
            "CL",
            "CO",
            "CR",
            "CU",
            "DO",
            "EC",
            "SV",
            "GQ",
            "GT",
            "HN",
            "MX",
            "NI",
            "PA",
            "PY",
            "PE",
            "PR",
            "ES",
            "UY",
            "VE",
          ];

          const euCountries = [
            "AT",
            "AE",
            "BE",
            "BG",
            "HR",
            "CY",
            "CZ",
            "DK",
            "EE",
            "FI",
            "FR",
            "DE",
            "GR",
            "HU",
            "IE",
            "IT",
            "LV",
            "LT",
            "LU",
            "MT",
            "NL",
            "PL",
            "PT",
            "RO",
            "SK",
            "SI",
            "ES",
            "SE",
            "MD",
          ];

          sessionStorage.setItem("redirectForLanguage", true);

          const isES = spanishSpeakingCountryCodes.includes(countryCode);

          if (window.location.href.split("/")[3] != "es") {
            if (!redirectData) {
              if (isES) {
                // console.log("User is from a Spanish-speaking country.");
                window.location.href = "/es/index.html";
              }
            }
          }

          const isEU = euCountries.includes(countryCode);

          if (isEU) {
            if (accepted != "yes" || accepted === null) {
              const modal = new $bootstrap.Modal("#EU-Residents-Notice", {
                keyboard: false,
              });

              modal.show();

              const closeBtn = document.getElementById("importantNotice");

              closeBtn?.addEventListener(
                "click",
                () => {
                  document.cookie = "accepted=yes; path=/";
                },
                { once: true },
              );
            }
          } else {
            // console.log("User is not from EU.");
          }
        })
        .catch((error) => {
          console.error("Error fetching geolocation data:", error);
        });
    }

    checkEUUser()
  }
}
</script>

<template>
  <div class="modal fade" id="EU-Residents-Notice" tabindex="-1" role="dialog"
    aria-labelledby="EU-Residents-NoticeLabel" aria-hidden="true" data-bs-backdrop="static">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <img :src="'/img/Logo.svg'" alt="" />
          <h2>Important Notice for EU Residents</h2>
          <p>
            The website you are trying to reach is operated by
            <span>DEDA Capital Markets (Pty) Ltd,</span> a regulated investment
            firm by the FSCA licence number 50640.
          </p>

          <p>
            The Investment services provided by this website are not directed at
            EU residents, fall outside the European regulatory framework and are
            not in scope of (among others) the Market Financial Instruments
            Directive (MiFID) II.
          </p>

          <p>
            Please click below if wish to continue anyway at your own exclusive
            initiative.
          </p>

          <button data-bs-dismiss="modal" id="importantNotice">Continue</button>
        </div>
      </div>
    </div>
  </div>
</template>
