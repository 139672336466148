import { defineNuxtPlugin } from "nuxt/app";
import type { Image } from "~/types/common";

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  const strapiUrl = config.public.strapiUrl;

  return {
    provide: {
      getStrapiImageSrc: (
        image: Image | null | undefined,
        fromAttributes: boolean = false,
      ): string => {
        if (!image) return "";

        const url = fromAttributes
          ? image?.attributes?.url
          : image?.data?.attributes?.url;
        if (!url) return "";

        return url.startsWith("http") ? url : `${strapiUrl}${url}`;
      },
    },
  };
});
