import { useStorage } from "@vueuse/core";

export function useIsLoading() {
  const loading = useStorage<boolean[]>("loading", []);

  const isLoading = computed(() => loading.value.length > 0);

  const setIsLoading = (value: boolean) => {
    if (value) {
      loading.value.push(true);
    } else {
      loading.value.pop();
    }
  };
  return { isLoading, setIsLoading };
}
