<template>
  <NuxtLayout>
    <FullScreenLoader v-if="isLoading" />
    <TheHeader />
    <main class="main">
      <div class="error-container">
        <div class="error-content">
          <div class="error-text">
            <h1>{{ $t("notFound.title") }}</h1>
            <span style="visibility: hidden">404 page</span>
            <p>{{ $t("notFound.body") }}</p>
            <br />

            <button @click="navigateHome" class="return-home-btn inline">
              {{ $t("notFound.button") }}
            </button>
          </div>
          <div class="error-image">
            <div class="glass-container shadow-effect">
              <div class="image-wrapper">
                <img
                  src="./public/img/404/404.png"
                  alt="404"
                  class="error-404"
                />
                <img
                  src="./public/img/404/candlecandle.png"
                  alt="Decorative elements"
                  class="decorative-elements"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- EU Modal -->
      <EuResidentModal />
    </main>
    <TheFooter />
  </NuxtLayout>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
import FullScreenLoader from "./components/FullScreenLoader.vue";
import TheHeader from "./components/TheHeader.vue";
import TheFooter from "./components/TheFooter.vue";
import EuResidentModal from "./components/EuResidentModal.vue";
import { useI18n } from "vue-i18n";

import { getSeoObject } from "~/helpers/seo";

const { isLoading } = useIsLoading();
const { data } = useCustomFetch("/api/strapi/common-datum");

const { data: seoData } = useCustomFetch("/api/strapi/default-seo");

useSeoMeta(getSeoObject(seoData.value?.seo));

provide("commonData", data);

const router = useRouter();

const navigateHome = () => {
  router.push("/");
};

const { t } = useI18n();

useHead(() => ({
  title: t("notFound.pageTitle"),
}));
</script>

<style lang="scss" scoped>
.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 1rem;
  text-align: center;
}

.error-content {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.error-image {
  order: -1;
  flex: 1;
  position: relative;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.error-text {
  flex: 1;
  padding: 0.5rem;
  text-align: center;

  h1 {
    font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: 0.75rem;
    color: black;
    word-wrap: break-word;
  }

  p {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 1rem;
    max-width: 44rem;
    color: black;
    word-wrap: break-word;
  }
}

.return-home-btn {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  text-decoration: none;
  border-radius: 1.25rem;

  &.inline {
    background: #190787;
    border: 0.0625rem solid #0d0250;
    color: #fff;
    box-shadow: 0 0 2.5rem rgba(25, 7, 135, 0.5);
    &:hover {
      box-shadow: 0 0 1.875rem rgba(25, 7, 135, 0.5);
    }
  }

  &.outline {
    border: 0.0625rem solid #cccccc;
    color: #cccccc;

    &:hover {
      background: #fff;
      color: #000;
      box-shadow: 0 0 1.875rem rgba(0, 0, 0, 0.12);
    }
  }
}

.glass-container {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 1.5rem;
  border-radius: 1.25rem;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(1rem);
  box-shadow: 0 0.625rem 1.875rem -0.125rem rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  overflow: visible;
}

.glass-container::before {
  content: "";
  position: absolute;
  top: -31%;
  left: -1%;
  width: 150%;
  height: 180%;
  background-image: url("/img/404/double-blur.png");
  background-position: center;
  background-size: cover;
  transform: rotate(180deg);
  filter: blur(1.25rem);
  opacity: 0.3;
  z-index: -1;
  pointer-events: none;
}

.image-wrapper {
  position: relative;
}

.error-404 {
  width: 100%;
  max-width: 14rem;
  margin: 0 auto;
  height: auto;
}

.decorative-elements {
  position: absolute;
  top: -23%;
  right: -7%;
  transform: translate(50%, -50%);
  width: clamp(2rem, calc(0.5rem + 5vw), 5rem);
  height: auto;
  z-index: 1;
}

/* Bootstrap container widths */
@media (min-width: 576px) {
  .error-content {
    max-width: 560px;
  }
}

@media (min-width: 768px) {
  .error-content {
    max-width: 740px;
  }
}

@media (min-width: 992px) {
  .error-content {
    max-width: 980px;
  }
}

@media (min-width: 1200px) {
  .error-content {
    max-width: 1160px;
  }
}

@media (min-width: 1400px) {
  .error-content {
    max-width: 1390px;
  }
}

@media (min-width: 600px) {
  .error-text h1 {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }

  .error-text p {
    font-size: 0.9375rem;
    margin-bottom: 1.25rem;
  }

  .return-home-btn {
    padding: 0.625rem 1.25rem;
    font-size: 0.9375rem;
    margin-bottom: 1.75rem;
  }

  .glass-container {
    padding: 2rem;
    width: calc(100% - 2rem);
    margin: 1rem;
    backdrop-filter: blur(1.5rem);
  }

  .error-404 {
    max-width: 16rem;
  }
}

@media (min-width: 768px) {
  .error-text {
    padding: 1rem;
  }

  .error-text h1 {
    font-size: 1.5rem;
  }

  .error-text p {
    font-size: 1rem;
  }

  .return-home-btn {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
  }

  .glass-container {
    padding: 2.5rem;
    width: calc(100% - 4rem);
    margin: 2rem;
    backdrop-filter: blur(2rem);
  }

  .error-404 {
    max-width: 18rem;
  }
}

@media (min-width: 1024px) {
  .error-content {
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    height: 100%;
  }

  .error-image {
    order: 0;
    margin-bottom: 0;
  }

  .error-text {
    text-align: left;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .error-text h1 {
    font-size: 1.75rem;
  }

  .error-text p {
    font-size: 1.0625rem;
    margin-bottom: 1.5rem;
  }

  .return-home-btn {
    padding: 0.75rem 1.75rem;
    font-size: 1.0625rem;
    margin-bottom: 0;
    align-self: flex-start;
  }

  .glass-container {
    padding: 3rem;
    width: calc(100% - 6rem);
    margin: 3rem;
    backdrop-filter: blur(2.5rem);
  }

  .error-404 {
    width: 80%;
    max-width: none;
  }

  .decorative-elements {
    width: clamp(3rem, calc(0.75rem + 3vw), 7.5rem);
  }
}

@media (min-width: 1440px) {
  .error-text h1 {
    font-size: 2.5rem;
  }

  .error-text p {
    font-size: 1.25rem;
  }

  .return-home-btn {
    padding: 0.75rem 2.5rem;
    font-size: 1.25rem;
  }

  .glass-container {
    padding: 4rem;
    backdrop-filter: blur(3rem);
  }
}

@media (min-width: 1920px) {
  .error-text h1 {
    font-size: 3rem;
  }

  .error-text p {
    font-size: 1.375rem;
  }

  .return-home-btn {
    padding: 0.875rem 2.8125rem;
    font-size: 1.375rem;
  }

  .glass-container {
    padding: 5rem;
    backdrop-filter: blur(4rem);
  }
}

@media (max-width: 400px) {
  .error-text h1 {
    font-size: 1rem;
  }

  .error-text p {
    font-size: 0.8125rem;
  }

  .return-home-btn {
    padding: 0.5rem 0.875rem;
    font-size: 0.8125rem;
  }

  .glass-container {
    padding: 1.25rem;
    margin: 0.5rem;
  }

  .error-404 {
    max-width: 12rem;
  }

  .decorative-elements {
    width: 2.5rem;
  }
}

@media (orientation: landscape) and (max-height: 500px) {
  .error-container {
    min-height: 100vh;
    padding: 0.5rem;
  }

  .glass-container {
    padding: 1.5rem;
  }

  .error-text h1 {
    font-size: 1.125rem;
  }

  .error-text p {
    font-size: 0.875rem;
  }

  .return-home-btn {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
  }
}
</style>