import md from "markdown-it";

export default defineNuxtPlugin(() => {
  const renderer = md("commonmark", {
    html: true,
    breaks: true,
  });

  return {
    provide: {
      mdRenderer: renderer,
    },
  };
});
