<script setup lang="ts">
import { getSeoObject } from "~/helpers/seo";

const { isLoading } = useIsLoading();
const { data } = useCustomFetch("/api/strapi/common-datum");

const { data: seoData } = useCustomFetch("/api/strapi/default-seo");

useSeoMeta(getSeoObject(seoData.value?.seo));

provide("commonData", data);
</script>

<template>
  <NuxtLayout>
    <FullScreenLoader v-if="isLoading" />
    <TheHeader />
    <main class="main">
      <NuxtPage />

      <!-- EU Modal -->
      <EuResidentModal />
    </main>
    <TheFooter />
  </NuxtLayout>
</template>

<style lang="scss">
@import "@/assets/scss/main";
</style>
