<template>
  <div id="the_loader">
    <div class="loader"></div>
  </div>
</template>

<style scoped>
#the_loader {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0;
  z-index: 999999;
  background-color: rgba(255 255 255 / 98%);
}

.loader {
  height: 15px;
  aspect-ratio: 5;
  background: radial-gradient(closest-side at 37.5% 50%, #190787 94%, #0000) 0 /
    calc(80% / 3) 100%;
  animation: l47 0.75s infinite;
}
@keyframes l47 {
  100% {
    background-position: 36.36%;
  }
}
</style>
