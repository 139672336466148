<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const commonData = inject("commonData");

const { data: footerData } = useCustomFetch("/api/strapi/footer");
const { data: footerColumnsData } = useCustomFetch("/api/strapi/footer-links");

const footerColumns = computed(() => {
  if (!footerColumnsData.value || footerColumnsData.value.length === 0) {
    return [
      { id: 1, attributes: { linkGroups: [] } },
      { id: 2, attributes: { linkGroups: [] } },
    ];
  }

  if (footerColumnsData.value.length === 1) {
    const groups = footerColumnsData.value[0].attributes.linkGroups;
    const midPoint = Math.ceil(groups.length / 2);
    return [
      { id: 1, attributes: { linkGroups: groups.slice(0, midPoint) } },
      { id: 2, attributes: { linkGroups: groups.slice(midPoint) } },
    ];
  }

  return footerColumnsData.value;
});

const sortedFooterColumns = computed(() => {
  const getLinkCount = (column) =>
    column.attributes.linkGroups.reduce(
      (sum, group) => sum + group.links.length,
      0,
    );

  return [...footerColumns.value].sort(
    (a, b) => getLinkCount(b) - getLinkCount(a),
  );
});
</script>

<template>
  <section v-if="footerData && footerColumns" id="footer" class="main-footer">
    <div class="footer-container">
      <div class="footer-wrapper">
        <div class="footer-column-left">
          <NuxtLinkLocale to="/" class="footer-logo">
            <img :src="$getStrapiImageSrc(commonData.logoWhite)" alt="Logo" />
          </NuxtLinkLocale>

          <div class="download-links footer-download-links">
            <NuxtLinkLocale
              v-for="link in footerData.downloads"
              :key="`download_${link.id}`"
              :to="link.link"
              :title="link.name"
            >
              <img
                :src="$getStrapiImageSrc(link.image)"
                :alt="link.name"
                width="142"
              />
            </NuxtLinkLocale>
          </div>

          <div v-if="footerData.contact" class="contact-info">
            <h2>
              <div style="display: flex; align-items: start">
                <span id="contact-title">{{ footerData.contact.name }}</span>

                <small>
                  <template
                    v-for="(contact, index) in footerData.contact.links"
                    :key="`contact_${contact.id}`"
                  >
                    <NuxtLinkLocale :to="contact.url">
                      {{ contact.name }}
                    </NuxtLinkLocale>
                    <br />
                    <br />
                    <span
                      v-if="index + 1 < footerData.contact.links.length"
                      class="contact-separator"
                    >
                      {{ contact.phone }}
                    </span>
                  </template>
                </small>
              </div>
            </h2>
          </div>
        </div>
        <div class="footer-column-right">
          <ul class="footer-menu">
            <li
              v-for="column in sortedFooterColumns"
              :key="`column_${column.id}`"
            >
              <template
                v-for="(group, index) in column.attributes.linkGroups"
                :key="`group_${group.id}`"
              >
                <h2 :class="{ 'promo-block': index > 0 }">{{ group.name }}</h2>

                <ul>
                  <li v-for="link in group.links" :key="`link_${link.id}`">
                    <NuxtLinkLocale
                      :to="link.url"
                      :target="link.openInNewTab ? '_blank' : '_self'"
                    >
                      {{ link.name }}
                    </NuxtLinkLocale>
                  </li>
                </ul>
              </template>
            </li>
          </ul>
        </div>
      </div>

      <div class="payment-social-section">
        <div class="payment-social-wrapper">
          <div v-if="footerData.socialsItems" class="social-links">
            <span class="follow-text">{{
              footerData.socialsTitle || "Follow us on:"
            }}</span>
            <div class="social-icons-container">
              <NuxtLinkLocale
                v-for="item in footerData.socialsItems"
                :key="`social_${item.id}`"
                :to="item.link"
                class="social-icon"
              >
                <img :src="$getStrapiImageSrc(item.image)" :alt="item.name" />
              </NuxtLinkLocale>
            </div>
          </div>
          <div class="payment-methods">
            <span class="payment-text">Payment Methods</span>
            <div class="payment-icons-wrapper">
              <div class="payment-icons">
                <div
                  v-for="image in footerData.paymentMethodsItems?.data"
                  :key="image.id"
                  class="payment-method-item"
                >
                  <img
                    :src="$getStrapiImageSrc(image, true)"
                    alt="Payment Method"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ul v-if="footerData.additionalLinks" class="additional-links">
        <li
          v-for="link in footerData.additionalLinks"
          :key="`additional-link_${link.id}`"
        >
          <NuxtLinkLocale
            :to="link.url"
            :target="link.openInNewTab ? '_blank' : '_self'"
          >
            {{ link.name }}
          </NuxtLinkLocale>
        </li>
      </ul>

      <div
        v-if="footerData.text"
        class="footer-disclaimer"
        v-html="$mdRenderer.render(footerData.text)"
      ></div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.main-footer {
  background: #000;
  color: #fff;
  padding: 40px 0;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;

  .footer-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }

  .footer-wrapper {
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .footer-column-left {
    margin-bottom: 40px;

    @media (min-width: 768px) {
      flex: 0 0 30%;
      max-width: 30%;
      margin-bottom: 0;
    }
  }

  .footer-column-right {
    @media (min-width: 768px) {
      flex: 0 0 65%;
      max-width: 65%;
    }
  }

  .footer-logo img {
    height: 50px;
    max-width: 100%;
  }

  .download-links {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    img {
      height: 42px;
      width: auto;
      max-width: 100%;
    }
  }

  .footer-menu,
  .footer-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .footer-menu {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;

    > li {
      flex: 1 1 calc(50% - 15px);
      color: #fff;
      font-size: 14px;
      line-height: 1.5;

      @media (min-width: 768px) {
        flex: 1 1 calc(50% - 15px);
        max-width: calc(50% - 15px);
      }

      @media (min-width: 992px) {
        flex: 1 1 calc(20% - 24px);
        max-width: calc(20% - 24px);
      }
    }

    li h2 {
      font-size: 20px;
      margin-bottom: 15px;

      @media (min-width: 768px) {
        font-size: 26px;
      }
    }

    li a {
      color: #fff;
      text-decoration: none;
      transition: opacity 0.2s ease;
      display: inline-block;
      padding: 5px 0;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .contact-info {
    margin-top: 40px;

    a {
      text-decoration: none;
      color: inherit;
      transition: opacity 0.2s ease;

      &:hover {
        opacity: 0.8;
      }
    }

    h2 {
      color: #fff;
      font-size: 20px;

      @media (min-width: 768px) {
        font-size: 25px;
      }

      small {
        font-size: 13px;
        display: block;
        margin-top: 5px;
      }
    }

    .contact-separator {
      margin-top: 10px;
    }
  }

  .payment-social-section {
    border-top: 1px solid #1b1d26;
    border-bottom: 1px solid #1b1d26;
    padding: 15px 0;
    margin-top: 40px;
  }

  .payment-social-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  .social-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
      width: auto;
    }
  }

  .follow-text {
    color: #fff;
    font-size: 14px;
    margin-bottom: 10px;
    text-align: center;

    @media (min-width: 768px) {
      margin-bottom: 0;
      margin-right: 10px;
      text-align: left;
    }
  }

  .social-icons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }

  .social-icon {
    background: #222221;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    transition: opacity 0.2s ease;
    padding: 5px;
    width: 30px;
    height: 30px;

    &:hover {
      opacity: 0.8;
    }

    img {
      max-width: 20px;
      max-height: 20px;
    }
  }

  .payment-methods {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      width: auto;
    }
  }

  .payment-text {
    color: #fff;
    font-size: 14px;
    white-space: nowrap;
    margin-bottom: 10px;
    text-align: center;

    @media (min-width: 768px) {
      margin-bottom: 0;
      margin-right: 15px;
      text-align: left;
    }
  }

  .payment-icons-wrapper {
    width: 100%;
  }

  .payment-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
  }

  .payment-method-item {
    img {
      height: 15px;
      width: auto;
      object-fit: contain;

      @media (min-width: 768px) {
        height: 30px;
      }
    }
  }

  .promo-block {
    margin-top: 30px;
  }

  .additional-links {
    padding: 0;
    list-style: none;
    margin: 20px auto 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;

    a {
      color: #747474;
      text-decoration: none;
      transition: color 0.2s ease;
      display: inline-block;
      padding: 5px 0;

      &:hover {
        color: #fff;
      }
    }
  }

  .footer-disclaimer {
    color: #fff;
    padding-top: 20px;
    text-align: justify;
    font-size: 15px;
    line-height: 1.5;
  }
}

/* RTL Support */
[dir="rtl"] {
  .main-footer {
    .footer-wrapper {
      @media (min-width: 768px) {
        flex-direction: row-reverse;
      }
    }

    .footer-menu {
      > li {
        text-align: right;
      }
    }

    .payment-social-wrapper {
      @media (min-width: 768px) {
        flex-direction: row-reverse;
      }
    }

    .follow-text,
    .payment-text {
      @media (min-width: 768px) {
        margin-right: 0;
        margin-left: 15px;
      }
    }

    .payment-methods {
      @media (min-width: 768px) {
        justify-content: flex-start;
      }
    }

    .contact-info .contact-separator {
      margin-top: 10px;
    }

    .additional-links {
      flex-direction: row-reverse;
    }

    .footer-disclaimer {
      text-align: right;
    }
  }
}

/* Mobile overrides */
@media (max-width: 767px) {
  .main-footer {
    #contact-title {
      margin-top: 5px;
      font-size: 15px;
    }
    .contact-separator {
      margin-top: 10px;
      margin: 10px;
    }
    .payment-methods {
      flex-direction: column !important;
      align-items: center !important;
      width: 100% !important;
    }

    .payment-text {
      margin-bottom: 15px !important;
      text-align: center !important;
      width: 100% !important;
    }

    .payment-icons-wrapper {
      width: 100% !important;
    }

    .payment-icons {
      justify-content: center !important;
      flex-wrap: wrap !important;
      width: 100% !important;
      gap: 10px !important;
    }

    .payment-method-item {
      margin: 0 !important;
    }

    .payment-method-item img {
      height: 15px !important;
      width: auto !important;
    }

    .social-links {
      width: 100% !important;
    }

    .follow-text {
      margin-bottom: 10px !important;
      text-align: center !important;
      width: 100% !important;
    }

    .social-icons-container {
      justify-content: center !important;
      width: 100% !important;
    }
  }
}

/* RTL Support for mobile */
[dir="rtl"] {
  .main-footer {
    @media (max-width: 767px) {
      #contact-title {
        margin-top: 5px;
        font-size: 15px;
      }

      .payment-methods {
        flex-direction: column !important;
      }

      .payment-text {
        margin-left: 0 !important;
      }

      .payment-icons {
        justify-content: center !important;
      }

      .payment-method-item {
        margin: 0 !important;
      }

      .social-links {
        flex-direction: column !important;
      }

      .follow-text {
        margin-left: 0 !important;
      }
    }
  }
}

#contact-title {
  margin-right: 20px;
}

[dir="rtl"] {
  #contact-title {
    margin-right: 0;
    margin-left: 20px;
  }
}


//798 
@media (max-width: 960px) {
  .payment-method-item img {
      height: 15px !important;
      width: auto !important;
    }
}
</style>