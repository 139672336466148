import { unref } from "vue";

interface Seo {
  title?: string;
  description?: string;
  keywords?: string;
  image?: any;
}

interface SeoObject {
  title?: string;
  ogTitle?: string;
  description?: string;
  keywords?: string;
  ogImage?: string;
}

export function getSeoObject(seo: Seo | null | undefined): SeoObject {
  const result: SeoObject = {};

  const strapiUrl = useRuntimeConfig().public.strapiUrl;

  if (!seo) return result;

  if (seo.title) {
    result.title = seo.title;
    result.ogTitle = seo.title;
  }

  if (seo.description) {
    result.description = seo.description;
  }

  if (seo.keywords) {
    result.keywords = seo.keywords;
  }

  if (seo.image) {
    const url = seo.image?.data?.attributes?.url;
    if (url) {
      result.ogImage = url.startsWith("http") ? url : `${strapiUrl}${url}`;
    }
  }

  return result;
}
