<script setup lang="ts">
import type { Image, Localizations } from "~/types/common";

export interface CtaData {
  title: string;
  createdAt: Date;
  updatedAt: Date;
  publishedAt: Date;
  locale: string;
  description: string;
  buttonText: string;
  items: CtaItem[];
  localizations: Localizations;
}

interface CtaItem {
  id: number;
  name: string;
  image: Image;
}

const { data } = useCustomFetch<any>("/api/strapi/call-to-action");
</script>

<template>
  <section v-if="data" id="callto">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1
            v-if="data?.title"
            v-html="$mdRenderer.renderInline(data.title)"
          ></h1>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="effect">
            <div
              class="blureffect"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="1500"
            ></div>

            <div
              class="blureffect rmore"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="1500"
            ></div>

            <div
              class="blureffect right"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="1500"
            ></div>

            <div class="calltoblock">
              <p style="white-space: pre-line">
                {{ data.description }}
              </p>

              <div class="row">
                <div
                  v-for="(item, index) in data.items"
                  :key="item.id"
                  class="col-md-4"
                >
                  <div
                    class="glassblock"
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom"
                    data-aos-delay="300"
                    :class="{ noarrow: index + 1 === data.items.length }"
                  >
                    <img
                      :src="$getStrapiImageSrc(item.image)"
                      height="75"
                      class="stepico"
                    />
                    <p class="desci" style="white-space: pre-line">
                      {{ item.name }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <NuxtLinkLocale href="/register" class="buttons">{{
            data.buttonText
          }}</NuxtLinkLocale>
        </div>
      </div>
    </div>
  </section>
</template>
