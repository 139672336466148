import { defineNuxtPlugin } from "nuxt/app";
import WOW from "wow.js";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive("wow", {
    mounted(el, binding) {
      el.classList.add("wow", binding.value);
      nextTick(() => {
        new WOW.WOW({
          live: false,
        }).init();
      });
    },
  });
});
