<script setup lang="ts">
// NOTE: This component was created because useSeoMeta doesn't work properly
// and seo meta tags are not being set in the head tag
import type { Image } from "~/types/common";

const strapiUrl = useRuntimeConfig().public.strapiUrl;
const location = useRequestURL();

function parseSeoImage(url?: string) {
  if (!url) return "";

  return url.startsWith("http") ? url : `${strapiUrl}${url}`;
}

const props = defineProps<{
  title?: string;
  description?: string;
  keywords?: string;
  image?: Image;
  schema?: string;
}>();

useSchemaOrg([props?.schema]);
</script>

<template>
  <Head>
    <!-- Primary Meta Tags -->
    <Title v-if="props?.title">{{ props?.title }}</Title>
    <Meta v-if="props?.title" name="title" :content="props?.title" />
    <Meta
      v-if="props?.description"
      name="description"
      :content="props?.description"
    />
    <Meta v-if="props?.keywords" name="keywords" :content="props?.keywords" />
    <Meta
      v-if="props?.image"
      property="og:image"
      :content="parseSeoImage(props?.image?.data?.attributes?.url)"
    />

    <!-- Open Graph / Facebook -->
    <Meta property="og:type" content="website" />
    <Meta v-if="location?.href" property="og:url" :content="location?.href" />
    <Meta v-if="props?.title" property="og:title" :content="props?.title" />
    <Meta
      v-if="props?.description"
      property="og:description"
      :content="props?.description"
    />
    <Meta
      v-if="props?.image"
      property="og:image"
      :content="parseSeoImage(props?.image?.data?.attributes?.url)"
    />

    <!-- Twitter -->
    <Meta property="twitter:card" content="summary_large_image" />
    <Meta
      v-if="location?.href"
      property="twitter:url"
      :content="location?.href"
    />
    <Meta
      v-if="props?.title"
      property="twitter:title"
      :content="props?.title"
    />
    <Meta
      v-if="props?.description"
      property="twitter:description"
      :content="props?.description"
    />
    <Meta
      v-if="props?.image"
      property="twitter:image"
      :content="parseSeoImage(props?.image?.data?.attributes?.url)"
    />
  </Head>
</template>
